export let searchStockItemParams = {
    cateringId: 0,
    name: null,
    unit: null,
    description: null,
    pageNumber:0,
    pageSize:10
}
export let searchStockParams = {
    stockItemId: 0,
    amount: null,
    minDate: null,
    maxDate: null,
    pageNumber:0,
    pageSize:10
}
export let searchItemCategoryParams = {
    cateringId: 0,
    name: null,
    description: null,
    pageNumber:0,
    pageSize:10
}
export let searchMenuItemAttributeParams = {
    cateringId: 0,
    name: null,
    pageNumber:0,
    pageSize:10
}
export let searchMenuItemParams = {
    cateringId: 0,
    customerId: 0,
    // itemCategoryId: 0,
    name: null,
    description: null,
    isPublic: null,
    pageNumber:0,
    pageSize:10
}
export let searchMenuParams = {
    cateringId: 0,
    name: null,
    description: null,
    pageNumber:0,
    pageSize:10
}
export let searchCateringParams = {
    name: null,
    description: null,
    publicMenu: null,
    customerId: null,   
    pageNumber:0,
    pageSize:10
}
export let searchCustomerParams = {
    cateringId: 0,
    name: null,
    description: null,
    pageNumber:0,
    pageSize:10
}
export let searchUserParams = {
    username: null,
    email: null,
    cateringId: 0,
    pageNumber:0,
    pageSize:10,
    roleName:null
}
export let searchRoleParams = {
    name: null,
    pageNumber:0,
    pageSize:10
}
export let searchLicenseParams = {
    cateringId: 0,
    licenseKey: null,
    status: null,
    pageNumber:0,
    pageSize:10
}
import { classNames } from 'primereact/utils';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Route, useHistory, useLocation } from 'react-router-dom';

import AppBreadcrumb from './AppBreadcrumb';
import AppFooter from './AppFooter';
import AppInlineProfile from './AppInlineProfile';
import AppMenu from './AppMenu';
import AppTopbar from './AppTopbar';

import PrimeReact, { addLocale } from 'primereact/api';
import { Tooltip } from 'primereact/tooltip';

import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css';
import 'primereact/resources/primereact.min.css';
import './App.scss';
import DashboardCatering from './components/DashboardCatering';
import DashboardWrapper from './components/DashboardWrapper';
import { customerOperator } from './constants/auth';
import AuthContext from './context';
import CateringOverview from './pages/Catering/CateringOverview';
import CateringTask from './pages/Catering/CateringTask';
import Customers from './pages/Catering/Customers';
import CustomerOverview from './pages/Customer/CustomerOverview';
import LicenseTask from './pages/License/LicenseTask';
import ActiveMenuOverview from './pages/Menu/ActiveMenu/ActiveMenuOverview';
import ItemCategory from './pages/Menu/ItemCategory/ItemCategory';
import MenuClient from './pages/Menu/MenuClient';
import MenuItem from './pages/Menu/MenuItem/MenuItem';
import MenuOverview from './pages/Menu/MenuOverview';
import CustomUserOrder from './pages/Order/CustomUserOrder';
import MenuOrder from './pages/Order/MenuOrder';
import MenuOrderCard from './pages/Order/MenuOrderCard';
import OrderVerification from './pages/Order/OrderVerification';
import OrdersDataTask from './pages/Order/OrdersDataTask';
import { useCustomMenu } from './utilities/menu';
import StockItem from './pages/Stock/StockItem/StockItem';
import Stock from './pages/Stock/Stock';
import { useTranslation } from 'react-i18next';
import PublicViewComponent from './pages/PublicView/PublicViewComponent';
import PublicMenuItem from './pages/PublicView/PublicMenuItem';
import OrderCardVerification from './pages/Order/OrderCardVerification';
import MenuItemAttribute from './pages/Menu/MenuItem/MenuItemAttribute';
import AppPublic from './AppPublic';
import GroupOrdersDataTask from './pages/Order/GroupOrderTask.js';
// import RegisterPublicUser from './pages/Users/RegisterPublicUser';
const App = () => {
    const authContext = useContext(AuthContext);

    const [menuActive, setMenuActive] = useState(false);
    const [menuMode, setMenuMode] = useState('static');
    const [darkMenu, setDarkMenu] = useState(false);
    const [overlayMenuActive, setOverlayMenuActive] = useState(false);
    const [topbarMenuActive, setTopbarMenuActive] = useState(false);
    const [staticMenuDesktopInactive, setStaticMenuDesktopInactive] = useState(false);
    const [staticMenuMobileActive, setStaticMenuMobileActive] = useState(false);
    const [activeTopbarItem, setActiveTopbarItem] = useState(null);
    const [inlineMenuActive, setInlineMenuActive] = useState(false);
    const [profileMode, setProfileMode] = useState('popup');
    const [configActive, setConfigActive] = useState(false);
    const [inputStyle, setInputStyle] = useState('outlined');
    const [ripple, setRipple] = useState(false);
    const copyTooltipRef = useRef();
    const location = useLocation();
    const { returnMenuList } = useCustomMenu()
    const history = useHistory();

    let menuClick = false;
    let configClick = false;
    let topbarItemClick = false;
    let inlineMenuClick = false;

    const menu = returnMenuList();
    const { t } = useTranslation();


    const routers = [
        { path: '/', component: DashboardWrapper, exact: true, meta: { breadcrumb: [{ parent: 'Dashboard', label: t('dashboard') }] } },
        { path: '/cateringHome', component: DashboardCatering, exact: true, meta: { breadcrumb: [{ parent: 'Dashboard', label: t('dashboard') }] } },
        { path: '/itemCategory', component: ItemCategory, meta: { breadcrumb: [{ parent: 'Menu', label: t('itemCategory') }] } },
        { path: '/menuItemAttribute', component: MenuItemAttribute, meta: { breadcrumb: [{ parent: 'menuItems', label: t('menuItemAttribute') }] } },
        { path: '/menuItems', component: MenuItem, meta: { breadcrumb: [{ parent: 'Menu', label: t('menuItems') }] } },
        { path: '/menu', component: MenuOverview, meta: { breadcrumb: [{ parent: 'UI Kit', label: t('menu') }] } },
        { path: '/activeMenu', component: ActiveMenuOverview, meta: { breadcrumb: [{ parent: 'Menu', label: 'Active menu' }] } },
        { path: '/orderClient', component: MenuOrder, meta: { breadcrumb: [{ parent: 'Menu', label: t('orderClient') }] } },
        { path: '/orderPanel', component: AppPublic, meta: { breadcrumb: [{ parent: 'Menu', label: t('orderClient') }] } },
        { path: '/orderClientCard', component: MenuOrderCard, meta: { breadcrumb: [{ parent: 'Menu', label: t('orderClient') }] } },
        { path: '/menuClient', component: MenuClient, meta: { breadcrumb: [{ parent: 'Menu', label: t('menuClient') }] } },
        { path: '/orders', component: OrdersDataTask, meta: { breadcrumb: [{ parent: 'Menu', label: t('orders') }] } },
        { path: '/verificationClient', component: OrderVerification, meta: { breadcrumb: [{ parent: 'Order', label: t('verificationClient') }] } },
        { path: '/verificationClientCard', component: OrderCardVerification, meta: { breadcrumb: [{ parent: 'Order', label: t('verificationClientCard') }] } },
        { path: '/catering', component: CateringTask, meta: { breadcrumb: [{ parent: 'Catering', label: t('caterings') }] } },
        { path: '/cateringOverview', component: CateringOverview, meta: { breadcrumb: [{ parent: 'Catering', label: t('catering') }] } },
        { path: '/customers', component: Customers, meta: { breadcrumb: [{ parent: 'Catering', label: t('customers') }] } },
        { path: '/license', component: LicenseTask, meta: { breadcrumb: [{ parent: 'License', label: t('license') }] } },
        { path: '/customerOverview', component: CustomerOverview, meta: { breadcrumb: [{ parent: 'Catering', label: t('customers') }] } },
        { path: '/customerOrder', component: CustomUserOrder, meta: { breadcrumb: [{ parent: 'Catering', label: t('customers') }] } },
        { path: '/stockItem', component: StockItem, meta: { breadcrumb: [{ parent: 'Stock', label: t('stockItem') }] } },
        { path: '/stock', component: Stock, meta: { breadcrumb: [{ parent: 'Stock', label: t('stock') }] } },
        { path: '/explore', component: PublicViewComponent, meta: { breadcrumb: [{ parent: 'Explore', label: t('explore') }] } },
        { path: '/exploreMenu', component: PublicMenuItem, meta: { breadcrumb: [{ parent: 'Explore', label: t('menu') }] } },
        { path: '/groupOrder', component: GroupOrdersDataTask, meta: { breadcrumb: [{ parent: 'Order', label: t('groupOrder') }] } },
        // { path: '/user/register', component: RegisterPublicUser, meta: { breadcrumb: [{ parent: 'Explore', label: t('menu') }] } },
    ];
    useEffect(() => {
        addLocale('en', {
            firstDayOfWeek: 1, // Monday
            dayNames: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
            dayNamesShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
            dayNamesMin: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
            monthNames: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
            monthNamesShort: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            today: 'Today',
            clear: 'Clear'
        });
        addLocale('srb', {
            firstDayOfWeek: 1, // Monday
            dayNames: ['Nedelja', 'Ponedeljak', 'Utorak', 'Sreda', 'Četvrtak', 'Petak', 'Subota'],
            dayNamesShort: ['Ned', 'Pon', 'Uto', 'Sre', 'Čet', 'Pet', 'Sub'],
            dayNamesMin: ['Ned', 'Pon', 'Uto', 'Sre', 'Čet', 'Pet', 'Sub'],
            monthNames: ['Januar', 'Februar', 'Mart', 'April', 'Maj', 'Jun', 'Jul', 'Avgust', 'Septembar', 'Oktobar', 'Novembar', 'Decembar'],
            monthNamesShort: ['Jan', 'Feb', 'Mar', 'Apr', 'Maj', 'Jun', 'Jul', 'Avg', 'Sep', 'Okt', 'Nov', 'Dec'],
            today: 'Danas',
            clear: 'Obriši'
        });
    },[])
    useEffect(() => {
        if (authContext?.roleDTO?.name == customerOperator) {
            setStaticMenuDesktopInactive(true)
        }
    }, [authContext?.roleDTO?.name])
    useEffect(() => {
        copyTooltipRef && copyTooltipRef.current && copyTooltipRef.current.updateTargetEvents();
    }, [location]);

    const onInputStyleChange = (inputStyle) => {
        setInputStyle(inputStyle);
    };

    const onRippleChange = (e) => {
        PrimeReact.ripple = e.value;
        setRipple(e.value);
    };

    const onMenuModeChange = (e) => {
        setMenuMode(e.value);
        setStaticMenuDesktopInactive(false);
        setOverlayMenuActive(false);

        if (e.value === 'horizontal') {
            setProfileMode('popup');
        }
    };

    const onMenuColorChange = (e) => {
        setDarkMenu(e.value);
    };

    const onProfileChange = (e) => {
        setProfileMode(e.value);
    };

    const onDocumentClick = () => {
        if (!topbarItemClick) {
            setActiveTopbarItem(null);
            setTopbarMenuActive(false);
        }

        if (!menuClick) {
            if (isHorizontal() || isSlim()) {
                setMenuActive(false);
            }
            hideOverlayMenu();
        }

        if (!inlineMenuClick && profileMode === 'inline' && isSlim() && !isMobile()) {
            setInlineMenuActive(false);
        }

        if (configActive && !configClick) {
            setConfigActive(false);
        }

        inlineMenuClick = false;
        configClick = false;
        topbarItemClick = false;
        menuClick = false;
    };

    const onMenuitemClick = (event) => {
        if (!event.item.items) {
            hideOverlayMenu();

            if (isSlim() || isHorizontal()) {
                setMenuActive(false);
            }
        }
    };

    const onRootMenuitemClick = () => {
        setMenuActive(prevMenuActive => !prevMenuActive);
    };

    const onMenuClick = () => {
        menuClick = true;

        if (inlineMenuActive && !inlineMenuClick) {
            setInlineMenuActive(false);
        }
    };

    const isMenuVisible = () => {
        if (isDesktop()) {
            if (menuMode === 'static')
                return !staticMenuDesktopInactive;
            else if (menuMode === 'overlay')
                return overlayMenuActive;
            else
                return true;
        } else {
            return true;
        }
    };

    const onMenuButtonClick = (event) => {
        menuClick = true;
        setTopbarMenuActive(false);

        if (isOverlay() && !isMobile()) {
            setOverlayMenuActive(prevOverlayMenuActive => !prevOverlayMenuActive);
        }
        else {
            if (isDesktop()) {
                setStaticMenuDesktopInactive(prevStaticMenuDesktopInactive => !prevStaticMenuDesktopInactive);
            }
            else {
                setStaticMenuMobileActive(prevStaticMenuMobileActive => !prevStaticMenuMobileActive);
            }
        }

        event.preventDefault();
    };

    const onProfileButtonClick = (event) => {
        setInlineMenuActive(prevInlineMenuActive => !prevInlineMenuActive);
        inlineMenuClick = true;

        if (isSlim() || isHorizontal()) {
            setMenuActive(false);
        }
    };

    const onTopbarMenuButtonClick = (event) => {
        topbarItemClick = true;
        setTopbarMenuActive(prevTopbarMenuActive => !prevTopbarMenuActive);

        hideOverlayMenu();

        event.preventDefault();
    };

    const onTopbarItemClick = (event, item) => {
        topbarItemClick = true;

        if (activeTopbarItem === item) {
            setActiveTopbarItem(null);
        }
        else {
            setActiveTopbarItem(item);
        }

        event.preventDefault();
    };

    const onConfigClick = () => {
        configClick = true;
    };

    const onConfigButtonClick = () => {
        setConfigActive(prevConfigActive => !prevConfigActive);
        configClick = true;
    };

    const hideOverlayMenu = () => {
        setOverlayMenuActive(false);
        setStaticMenuMobileActive(false);
    };

    const isDesktop = () => {
        return window.innerWidth > 896;
    };

    const isMobile = () => {
        return window.innerWidth <= 896;
    };

    const isOverlay = () => {
        return menuMode === 'overlay';
    };

    const isHorizontal = () => {
        return menuMode === 'horizontal';
    };

    const isSlim = () => {
        return menuMode === 'slim';
    };

    const isStatic = () => {
        return menuMode === 'static';
    };

    const hasInlineProfile = profileMode === 'inline' && !isHorizontal();

    const containerClassName = classNames('layout-wrapper',
        {
            'layout-static': isStatic(),
            'layout-overlay': isOverlay(),
            'layout-overlay-active': overlayMenuActive,
            'layout-horizontal': isHorizontal(),
            'layout-slim': isSlim(),
            'layout-static-inactive': staticMenuDesktopInactive,
            'layout-mobile-active': staticMenuMobileActive,
            'layout-menu-dark': darkMenu,
            'layout-menu-light': !darkMenu,
            'p-input-filled': inputStyle === 'filled',
            'p-ripple-disabled': !ripple
        });

    const menuContainerClassName = classNames('layout-menu-container', { 'layout-menu-container-inactive': !isMenuVisible() });

    return (
        <div className={containerClassName} onClick={onDocumentClick}>
            <Tooltip ref={copyTooltipRef} target=".block-action-copy" position="bottom" content="Copied to clipboard" event="focus" />

            <AppTopbar topbarMenuActive={topbarMenuActive} activeTopbarItem={activeTopbarItem} onMenuButtonClick={onMenuButtonClick} onTopbarMenuButtonClick={onTopbarMenuButtonClick} onTopbarItemClick={onTopbarItemClick}
                isHorizontal={isHorizontal()} profileMode={profileMode} isMobile={isMobile} />

            <div className={menuContainerClassName} onClick={onMenuClick}>
                <div className="layout-menu-logo">
                    <button className="p-link" onClick={() => history.push('/')}>
                        <img id="layout-menu-logo" src="assets/layout/images/logo-white.png" library="babylon-layout" alt="babylon-logo" />
                    </button>
                </div>
                <div className="layout-menu-wrapper">
                    <div className="menu-scroll-content">
                        {hasInlineProfile && <AppInlineProfile inlineMenuActive={inlineMenuActive} onProfileButtonClick={onProfileButtonClick} />}
                        <AppMenu model={menu} menuMode={menuMode} active={menuActive} onMenuitemClick={onMenuitemClick} onRootMenuitemClick={onRootMenuitemClick} />
                    </div>
                </div>
            </div>

            <div className="layout-main">
                <AppBreadcrumb routers={routers} />

                <div className="layout-content">
                    {
                        routers.map((router, index) => {
                            if (router.exact) {
                                return <Route key={`router${index}`} path={router.path} exact component={router.component} />
                            }

                            return <Route key={`router${index}`} path={router.path} component={router.component} />
                        })
                    }
                </div>

                <AppFooter />
            </div>

            {/* <AppConfig configActive={configActive} menuMode={menuMode} onMenuModeChange={onMenuModeChange}
                isDarkMenu={darkMenu} onMenuColorChange={onMenuColorChange}
                profileMode={profileMode} onProfileChange={onProfileChange} onConfigClick={onConfigClick} onConfigButtonClick={onConfigButtonClick}
                rippleActive={ripple} onRippleChange={onRippleChange} inputStyle={inputStyle} onInputStyleChange={onInputStyleChange}></AppConfig> */}

            {staticMenuMobileActive && <div className="layout-mask"></div>}
        </div>
    );
}

export default App;

import React, { useEffect, useState } from 'react';
import 'react-app-polyfill/ie11';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import AppWrapper from './AppWrapper';
//import * as serviceWorker from './serviceWorker';
import { I18nextProvider } from 'react-i18next';
import LoginComponent from './components/auth/LoginComponent';
import { JWT_NAME, customerAdmin, customerUser } from './constants/auth';
import AuthContext from './context';
import i18n from './i18n';
import ChangePasswordForm from './pages/Users/ChangePasswordForm';
import AuthService from './service/AuthService';
import { createCookie } from './utilities/general';
import AppPublic from './AppPublic';
import { store } from './store/store';
import { Provider, useDispatch } from 'react-redux';
const LoadingPage = () => {
	return (
		<div class="loader-screen">
			<div class="loader">
				<div class="bar1"></div>
				<div class="bar2"></div>
				<div class="bar3"></div>
				<div class="bar4"></div>
				<div class="bar5"></div>
				<div class="bar6"></div>
			</div>
		</div>
	)
}
const MainComponent = () => {
	const [isLoading, setIsLoading] = useState(true)
	const [isAuthenticated, setIsAuthenticated] = useState(false);
	const [userData, setUserData] = useState()
	const authService = new AuthService();
	useEffect(() => {

		authService.checkSession().then((data) => {
			authService.getUserData().then((data) => {
				setIsLoading(false)
				setIsAuthenticated(true)
				setUserData(data)
			}).catch(setIsAuthenticated(false))
		}).catch((data) => {
			setIsLoading(false)
			setIsAuthenticated(false)
			createCookie(JWT_NAME, '', -1)
		})
	}, [])
	if (isLoading) {
		return (
			<LoadingPage />
		)
	} else if (isAuthenticated) {
		if (userData?.status === "CREATED" || userData?.status === "PASSWORD_RESET") {
			return <ChangePasswordForm userData={userData} />
		}
		if (userData?.roleDTO?.name == customerUser) {
			return (
				<AuthContext.Provider value={userData}><AppPublic /></AuthContext.Provider>
			)
		}
		return (
			<AuthContext.Provider value={userData}><AppWrapper></AppWrapper></AuthContext.Provider>
		)
	}
	return (
		<LoginComponent />
	)
}
ReactDOM.render(
	<BrowserRouter>
		<I18nextProvider i18n={i18n}>
			<Provider store={store}>

				<MainComponent />
			</Provider>
		</I18nextProvider>
	</BrowserRouter>,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
//serviceWorker.unregister();
